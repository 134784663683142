<template>
  <div class="protocol">
    <div class="protocol-box">
        <h1 class="title">注册协议</h1>
        <p class="p1">欢迎您加入果老网（http://www.guolaoai.com），您的支持将是我们前进的动力！
            为保障您的权益，在正式成为本网站用户前，请您认真仔细阅读本协议，
            谢谢您的配合！</p>
        <p class="p2">果老网系由杭州果老网数字科技有限公司成立并运营，
            以下所称“本网站”即包含了果老网本身及网站所有者杭州果老网数字科技有限公司。
            在您注册成为本网站用户前，您已充分阅读并理解本注册协议的所有条款。
            您同意以下条款并注册后，将有权依据本协议的条款接受本网站的服务，
            同时有义务接受本协议条款的约束。您勾选“同意协议并注册”按钮后，
            即视为双方达成本协议，协议内容对双方产生法律效力。</p>
        <p class="p3">第一条 法律声明及重要提示</p>
        <p class="p2">本服务协议内容包括以下条款及已经发布的或将来可能发布的各类规则。
          所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。
          本协议是由用户与本网站共同签订的，适用于用户在本网站的全部活动。</p>
        <p class="p2">任何人在满足本协议规定的资格条件下基于本协议的约定注册成为用户时，
          必须审慎阅读、充分理解本协议各条款的内容，特别是对用户权利限制的条款；
          免除或限制本网站责任的条款，并对本协议内容完全了解及理解后选择接受或不接受本协议。
          一经注册或使用本网站服务即视为对本协议全部条款已充分理解并完全接受，
          用户承诺遵守中国现行的法律、法规、规章及其他规范性文件的规定，如有违反而导致任何法律后果的发生，
          用户将以自己的名义独立承担所有相应的法律责任。</p>
        <p class="p3">第二条 协议变更</p>
        <p class="p2">本网站有权根据需要不定时地制定、修改本协议及其他各类协议、规则，
          如本协议及其他各类协议、规则有任何变更，本网站将在公告区刊载公告，经修订的协议、规则一经公布后，立即自动生效。
          用户应不时地注意本协议及其他协议、规则的变更，若用户不同意相关变更，用户有权停止使用本网站提供的服务。如果发生本条所述变更后用户仍继续使用本网站服务的，
          则视为用户接受本条所述变更并将按照变更后的协议、规则执行。</p>
        <p class="p3">第三条 履行承诺</p>
        <p class="p2">用户确认本服务协议后，本服务协议即在用户和本网站之间产生法律效力。
          用户按照本网站规定的注册程序成功注册为用户，即表示同意并签署了本服务协议。
          双方应严格按照本协议之约定履行本注册协议之内容。</p>
        <p class="p3">第四条 使用限制</p>
        <p>1、用户承诺合法使用本网站提供的服务及网站内容。
          禁止在本网站从事任何可能违反中国现行的法律、法规、规章和政府规范性文件的行为或者任何未经授权使用本网站的行为。</p>
        <p>2、本网站只接受中华人民共和国18周岁以上的具有完全民事行为能力的自然人或依据中国法律法规成立的机关、企事业单位、社团组织或其他组织成为网站用户。
          如用户不符合资格，请勿注册。本网站保留中止或终止用户身份的权利。</p>
        <p>3、用户注册成功后，自行保管自己的用户名和密码，不得将本网站的用户名转让给第三方或者授权给第三方使用。
          用户确认，使用其本人用户名和密码登陆本网站后在本网站的一切行为均代表用户本人并由用户本人承担相应的法律后果</p>
        <p>4、用户有义务在注册时提供自己的真实资料，并保证诸如电子邮件地址、联系电话、联系地址、邮政编码等内容的有效性及安全性。
          若上述信息发生变更，用户应及时更新。</p>
        <p>5、经国家生效法律文书或行政处罚决定确认用户存在违法行为，或者本网站有足够事实依据可
          以认定用户存在违法或违反本服务协议的行为的，本网站有权终止本协议。</p>
        <p>6、用户同意接受来自本网站的信息，包括但不限于活动信息、交易信息、促销信息等。</p>
        <p class="p3">第五条 外部链接</p>
        <p class="p2">本网站含有到其他网站的网络链接，但本网站不能保证第三方网站上的信息的真实性和有效性。
          您应按照第三方网站的服务协议使用第三方网站，而不是按照本协议。第三方网站的内容、产品、广告和其他任何信息均由您自行判断并承担风险，而与本网站无关。</p>
        <p class="p3">第六条 不保证条款</p>
        <p class="p2">由于本网站不能随时预见到任何技术上的问题或其他困难，本网站是在现有技术基础上提供的服务，本网站不对交易标的及本服务提供任何形式的保证，包括但不限于以下事项：</p>
        <p>（1）本网站提供的服务符合你的要求；</p>
        <p>（2）本网站提供服务不受干扰、能够及时提供安全可靠或免于出错；</p>
        <p>（3）通过本网站服务购买或取得的产品、服务或资讯是正确或可靠的。</p>
        <p class="p3">第七条 免责及责任限制条款</p>
        <p class="p2">在任何情况下，本网站对用户使用本网站服务而产生的任何形式的直接或间接损失均不承担法律责任，包括但不限于资金损失、利润损失、营业中断损失等。
          因为本网站或者涉及的第三方网站的设备、系统存在缺陷或者因为计算机病毒造成的损失，本网站均不负责赔偿，
          用户的补救措施只能是与本网站终止本协议并停止使用本网站。但是，中国现行法律、法规另有规定的除外。</p>
        <p class="p2">用户自本网站或本网站工作人员的服务取得的资讯或建议，无论是口头的或书面的，均不构成本网站对本网站服务的任何保证，用户应自行判断并承担风险及责任。</p>
        <p class="p2">除本协议另有约定外，在任何情况下，本网站对本协议所承担的违约责任、赔偿责任总额不超过向用户收取的当次服务费用总额。</p>
        <p class="p3">第八条 网站内容监测</p>
        <p class="p2">本网站没有义务监测网站内容，但是用户确认并同意本网站有权不时地根据法律、法规、政府要求透露、修改或者删除必要的、合适的信息，以便更好地运营本网站并保护自身及本网站的其他合法用户。</p>
        <p class="p3">第九条 系统中断或故障</p>
        <p class="p2">系统因下列状况无法正常运作，使用户无法使用各项服务时，本网站不承担损害赔偿责任，该状况包括但不限于：</p>
        <p>（1）本网站公告之系统维护期间；</p>
        <p>（2）电信设备出现故障不能进行数据传输的；</p>
        <p>（3)因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力因素，造成本网站系统故障不能执行业务的；</p>
        <p>（4）由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟等。</p>
        <p class="p3">第十条 隐私规则</p>
        <p>1、本网站对于用户提供的、自行收集的、经认证的个人信息将按照本规则予以保护、使用或者披露。</p>
        <p>2、涉及的个人资料</p>
        <p class="p2">(a)用户提供给本网站的个人资料及用户同意本网站收集的个人资料的主要目的在于向用户提供一个更加完善、顺利、有效的服务。</p>
        <p class="p2">(b)本网站可能自公开来源了解用户的情况，并为其度身订造本网站服务、解决争议并有助确保在网站进行安全交易。</p>
        <p class="p2">(c)本网站按照用户在本网站网址上的行为自动追踪关于用户的某些资料。本网站利用这些资料进行有关本网站之用户的人口统计、兴趣及行为的内部研究，
          以更好地了解用户及向用户和本网站的用户社区提供更好的服务。</p>
        <p class="p2">(d)本网站在本网站的某些网页上使用诸如“Cookies”的资料收集装置。</p>
        <p class="p2">(e)如果用户将个人通讯信息（例如：手机短信、电邮或信件）交付给本网站，
          或如果其他用户或第三方向本网站发出关于用户在本网站上的活动或登录事项的通讯信息，本网站可以将这些资料收集在用户的专门档案中。</p>
        <p>3、本网站对用户的资料的使用</p>
        <p class="p2">(a)用户同意本网站可使用关于用户的个人资料（包括但不限于本网站持有的有关用户的档案中的资料，
          及本网站从用户目前及以前在本网站上的活动所获取的其他资料）以解决争议、对纠纷进行调停、有助于确保在本网站进行安全交易，并执行本网站的服务协议。</p>
        <p class="p2">(b)本网站有时候可能调查多个用户以识别问题或解决争议， 特别是本网站可审查用户的资料以区分使用多个用户名或别名的用户。</p>
        <p class="p2">(c)为禁止用户在本网站上的欺诈、非法或其他刑事犯罪活动，使本网站免受其害，用户同意本网站可通过人工或自动程序对用户的个人资料进行评价。</p>
        <p class="p2">(d)用户同意本网站可以使用用户的个人资料以改进本网站的推广和促销工作、分析网站的使用率、改善本网站的内容和产品推广形式，并使本网站的网站内容、设计和服务更能符合用户的要求。
          这些使用能改善本网站的网页，以调整本网站的网页使其更能符合用户的需求，从而使用户在使用本网站服务时得到更为顺利、有效、安全及度身订造的交易体验。</p>
        <p class="p2">(e)用户同意本网站利用用户的资料与用户联络并向用户传递（在某些情况下）针对用户的兴趣而提供的信息，例如：有针对性的广告条、行政管理方面的通知、产品提供以及有关用户使用本网站的通讯。</p>
        <p>4、本网站承诺对用户的资料采取保密措施，不向第三方披露，不授权第三方使用您的资料，除非：</p>
        <p class="p2">(a)虽然本网站采用行业标准惯例以保护用户的个人资料，鉴于技术限制，本网站不能确保用户的全部私人通讯及其他个人资料不会通过本隐私规则中未列明的途径泄露出去。</p>
        <p class="p2">(b)本网站根据有关法律要求向司法机关和政府部门提供；</p>
        <p class="p2">(c)本网站为解决举报事件、提起诉讼根据实际需要进行提交；</p>
        <p class="p2">(d)本网站为防止严重违法行为或刑事犯罪发生而采取必要合理行为需要提交的；</p>
        <p class="p2">(e)本网站向用户提供产品、服务、信息而向第三方提供的；</p>
        <p class="p2">(f)其他有必要提供的情形。</p>
        <p>5、密码的安全性</p>
        <p class="p2">用户须对其注册时所使用的用户名和密码所采取的一切行为负责。因此，用户不要向任何第三方披露用户在本网站的用户名和密码，否则由此造成的损失由用户自行承担。</p>
        <p class="p3">第十一条 索赔条款</p>
        <p class="p2">因用户违反本协议或任何法律、法规或侵害第三方的权利，而引起第三方对本网站提出的任何形式的索赔、要求、诉讼。本网站有权向您追偿相关损失，
          包括但不限于本网站律师费、诉讼费用、名誉损失及向第三方支付的补偿金等。</p>
        <p class="p3">第十二条 帐号注销</p>
        <p>用户同意并理解，如发生如下情形，本网站可以采取中止、终止服务或注销帐号等措施：</p>
        <p>（1）用户提交的资料错误、不实、过时、不完整，经本网站催告后仍不改正的；</p>
        <p>（2）用户连续36个月未使用其账户登录本网站或系统的；</p>
        <p>（3）用户有侵犯本网站或他人合法权益或其他严重违反本网站规则的行为的；</p>
        <p>（4）其他违规行为</p>
        <p>以上情形，本网站不承担任何责任，用户将自行承担由此产生的任何直接或间接的损失或其他不利后果。</p>
        <p class="p3">第十三条 协议终止</p>
        <p class="p2">除非本网站终止本协议或者用户申请终止本协议且经本网站同意，否则本协议始终有效。本网站有权在不通知用户的情况下在任何时间终止本协议或者限制用户使用本网站。
          但本网站的终止行为不能免除用户根据本服务协议或在本网站生成的其他协议项下的还未履行完毕的义务。</p>
        <p class="p3">第十四条 知识产权</p>
        <p>1、本网站平台上所有内容，包括但不限于平台架构、平台程序、画面安排、网页设计、图片、资讯、资料，均由本网站或其他权利人依法拥有其知识产权，包括但不限于著作权、商标权、专利权、商业秘密等</p>
        <p>2、非经本网站或其他权利人书面同意，包括用户在内的任何人都不得擅自使用、复制、修改、传播、发行或发表前述平台架构、平台程序、画面安排、网页设计、图片、资讯、资料等。</p>
        <p>3、用户未经本网站事先书面同意不得下载或修改本网站平台或其任何部分。不得对本网站平台或其内容进行任何商业利用；不得为商业利用而下载或拷贝账户信息或使用任何数据采集、收集或摘录工具。
          未经本网站事先书面许可，不得对本网站平台的内容进行系统获取以直接或间接创建或汇编数据库或人名地址录。</p>
        <p class="p3">第十五条 适用法律和管辖</p>
        <p class="p2">因本网站提供服务所产生的争议均适用中华人民共和国法律，并由本网站住所地的杭州市萧山区人民法院管辖。</p>
        <p class="p3">第十六条 完整协议</p>
        <p class="p2">本协议由本协议条款与本网站公示的各项规则组成，具有同等法律效力。当这些条款存在冲突时，本网站有权进行解释并选择优先适用。</p>
        <p class="p3">第十七条 条款的独立性</p>
        <p class="p2">若本协议的部分条款被认定为无效或者无法实施时，本协议中的其他条款仍然有效。</p>
    </div>
    
  </div>
</template>

<script> 
export default {
  name: 'protocol',
  components: {
      
  },
  data(){
      return{
          
      }
  },
  watch:{
      
  },
  methods:{
     
  }
}

</script>

<style scoped>
.protocol{
  background-color: #fcfcfc;
}
  .protocol-box{
      width: 800px;
      margin: 20px auto 20px;
  }
  .protocol-box>p{
    margin-bottom: 20px;
    color: #333333;
  }
  .title{
    text-align: center;
    font-size: 20px;
    color: #333333;
    margin-bottom: 20px;
  }
  .p1{
      text-indent: 2em;
      font-weight: 600;
  }
  .p2{
    text-indent: 2em;
  }
  .p3{
    font-weight: 600;
  }
</style>
